<template>
    <div>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <div>
          <h5 class="mb-3">編號格式</h5>
          <hr class="full-width"/>
          <b-row>
              <b-col cols="12" md="6" class="prefix-box" v-for="(item, index) of prefixData" :key="'content'+index">
                <b-row class="align-items-center">
                  <b-col cols="6" md="3">
                    <span>{{ item.name }}:</span>
                  </b-col>
                  <b-col cols="6" md="9">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-input v-model="item.prefix" style="min-width:200px;"></b-form-input>
                      </b-input-group-prepend>
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.desired_length"
                        @focus="inputFocus(item, item.desired_length)"
                        @blur="inputBlur(item, item.desired_length)"
                        @input="handleInput(item)"
                      />
                      <feather-icon
                      v-if="ability.can('update', 'prefixes')"
                          icon="SaveIcon"
                          class="cursor-pointer edit-btn text-success"
                          @click="onSubmit(item)"
                        /> 
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>
         
          </b-row>
        </div>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BOverlay,
  } from "bootstrap-vue";
  import { ref, watch } from "@vue/composition-api";
  import store from "@/store";
  import Ripple from "vue-ripple-directive";
  import "animate.css";
  import ability from "@/libs/acl/ability"
  
  export default {
    data() {
      return {
        temp_size: null,
      };
    },
    directives: {
      Ripple,
    },
    components: {
      BForm,
      BFormGroup,
      BFormInput,
      BFormFile,
      BFormInvalidFeedback,
      BFormCheckbox,
      BFormRadioGroup,
      BFormRadio,
      BInputGroup,
      BInputGroupPrepend,
      BRow,
      BCol,
      BButton,
      BOverlay,
    },
    methods: {
      handleInput(item) {
        const parsedInput = parseInt(item.desired_length);
        if (!isNaN(parsedInput) && parsedInput >= 1 && parsedInput <= 10) {
          item.desired_length = parsedInput.toString();
        } else {
          item.desired_length = "";
        }
      },
      inputFocus(item, num) {
        const size = num.length;
        item.desired_length = size;
        this.temp_size = size;
      },
      inputBlur(item, size) {
        let num = "1";
        while (num.length < (size || this.temp_size)) num = "0" + num;
        item.desired_length = num;
        this.temp_size = null;
      },
      onSubmit(data) {
        this.$swal({
          title: "你確定嗎?",
          showCancelButton: true,
          confirmButtonText: "提交",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            data.desired_length = data.desired_length.length;
            store
              .dispatch("prefix/editPrefix", data)
              .then((response) => {
                this.loading = false;
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
      makeToast(variant = null, title = null, content = null) {
        this.$bvToast.toast(content, {
          title: title,
          variant,
          solid: true,
        });
      },
    },
    props: {
      prefixData: {
        type: Array,
        required: true,
      },
    },
  
    setup(props, { emit }) {
      const loading = ref(false);
  
      return {
        loading,
        ability
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  .prefix-box {
    margin-bottom:2rem;
    .input-group-prepend {
      width: 50px;
      input {
        border-radius: 0.357rem 0 0 0.357rem;
      }
    }
  }
  
  .edit-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor:pointer
  }
  </style>
  
  <style lang="scss" scoped>
  </style>
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPrefixes(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/prefixes/")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editPrefix(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/prefixes", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  }
};
<template>
    <component :is="prefixData === undefined ? 'div' : 'b-card'">
      <b-alert variant="danger" :show="prefixData === undefined">
        <h4 class="alert-heading">獲取商品數據時出錯</h4>
        <div class="alert-body">
          找不到相關商品記錄。請查看
          <b-link class="alert-link" :to="{ name: 'prefixes-list' }">
            商品名單
          </b-link>
        </div>
      </b-alert>
  
      <prefix-edit-tab-information
        v-if="prefixData"
        :prefix-data="prefixData"
        @refetch-data="refetchData"
        class="p-sm-2"
      />
  
      <!-- @refetch-data="refetchData" -->
    </component>
  </template>
  
  <script>
  import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
  import { ref, onUnmounted, onMounted } from "@vue/composition-api";
  import store from "@/store";
  import router from "@/router";
  import prefixStoreModule from "../prefixStoreModule";
  import PrefixEditTabInformation from "./prefixEditTabInformation.vue";
  
  export default {
    components: {
      PrefixEditTabInformation,
      BCard,
      BAlert,
      BTabs,
      BTab,
      BLink,
    },
    setup() {
      const prefixData = ref([]);
      const prefix_APP_STORE_MODULE_NAME = "prefix";
  
      if (!store.hasModule(prefix_APP_STORE_MODULE_NAME))
        store.registerModule(prefix_APP_STORE_MODULE_NAME, prefixStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(prefix_APP_STORE_MODULE_NAME))
          store.unregisterModule(prefix_APP_STORE_MODULE_NAME);
      });
  
      const refetchData = () => {
        store
          .dispatch("prefix/fetchPrefixes")
          .then((response) => {
            prefixData.value = response.data.prefixes;
            prefixData.value.forEach((ele) => {
              ele.desired_length = pad(1,ele.desired_length);
            });
            console.log(prefixData.value);
          })
          .catch((error) => {
            console.log("error when fetching prefix", error);
            if (error.response.status === 404) {
              prefixData.value = undefined;
            }
          });
      };
      const pad = (num, size) => {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
      };
      // const prefixStatusOptions = ref([]);
  
      // const refetchOption = () => {
      //   storegh
      //     .dispatch("app/fetchOptionList", {
      //       prefix_statuses: true,
      //     })
      //     .then((response) => {
      //       prefixStatusOptions.value = response.data.prefix_statuse;
      //     });
      // };
  
      onMounted(() => {
        refetchData();
      });
  
      return {
        prefixData,
        refetchData,
        pad
      };
    },
  };
  </script>